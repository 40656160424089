<template>
  <div id="sponsors">
    <app-navbar></app-navbar>
    <div class="container padding-top">
      <div class="sponsorsBlock">
        <!--        <p class="pt-4 pt-lg-0 text-center">{{ "Sponsors.financing" | staticTranslation }}</p>-->
        <p class="pt-4 pt-lg-0 text-center">
          {{ "Sponsors.financingJmm" | staticTranslation }}
        </p>
        <div
          class="row pt-lg-4 pb-lg-4 d-flex justify-content-center align-items-center"
        >
          <div class="col-lg-3 text-center pb-lg-4">
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/Brno_Logo_Red.jpg"
              alt="Logo_Brno"
            />
          </div>
          <div class="col-lg-3 text-center pb-lg-4">
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/Logotyp_jihomoravsky_kraj_CMYK.png"
              alt="JMK_Logo"
            />
          </div>
          <div class="col-lg-3 text-center pb-lg-4">
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/mjcr.jpg"
              alt="Logo_Mkcr"
            />
          </div>
          <div class="col-lg-3 text-center pb-lg-4">
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/logo_Brno_stred_barevne.jpg"
              alt="Logo_Brno_Stred"
            />
          </div>
        </div>
        <p class="pt-4 ptl-lg-0 text-center">
          {{ "Sponsors.auspices" | staticTranslation }}
        </p>
        <div class="row pt-lg-4 pb-lg-4">
          <div class="col-lg-4 text-center pt-lg-0 pt-4">
            <p class="text-center">
              {{ "Sponsors.mainPartner" | staticTranslation }}
            </p>
            <a href="https://www.ticbrno.cz/"
              ><img
                class="img-fluid"
                src="@/assets/sponsors/logo_tic_black.png"
                alt="Tic_brno"
            /></a>
          </div>
          <div class="col-lg-4 text-center pb-4 pb-lg-0 pt-4 pt-lg-0">
            <p class="text-center">
              {{ "Sponsors.membership" | staticTranslation }}
            </p>
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/Logo_Brno_Unesco-300x130.png"
              alt="city_of_music"
            />
          </div>
          <div class="col-lg-4 text-center">
            <p class="text-center">
              {{ "Sponsors.framework" | staticTranslation }}
            </p>
            <img
              class="img-fluid"
              src="@/assets/sponsors/brno-město-hudby.jpg"
              alt="Brno_město_hudby"
            />
          </div>
        </div>
        <p class="text-center">
          {{ "Sponsors.mainMedia" | staticTranslation }}
        </p>
        <div class="col-lg-4 offset-lg-4 text-center pb-lg-4">
          <img
            class="img-fluid pt-lg-4"
            width="100%"
            src="@/assets/sponsors/CT-V1-lg-jb-cerna.png"
            alt="Česká Televize"
          />
        </div>
        <p class="text-center pt-lg-4 pt-4">
          {{ "Sponsors.mediaPartners" | staticTranslation }}
        </p>
        <div
          class="row text-center pt-lg-4 pb-lg-4 align-items-center justify-content-center"
        >
          <div class="col-lg-4 pt-4 pb-4">
            <img
              width="70%"
              src="@/assets/sponsors/Mlada-fronta-dnes-Logo.png"
              alt="Mladá fronta dnes"
            />
          </div>
          <div class="col-lg-4">
            <img
              class="img-fluid pt-lg-3"
              width="30%"
              src="@/assets/sponsors/Logo_adjust.png"
              alt="Adjust"
            />
          </div>
          <div class="col-lg-4 pt-4 pb-4">
            <img
              width="65%"
              class="img-fluid pt-lg-1"
              src="@/assets/sponsors/hitradio.svg"
              alt="hitradio"
            />
          </div>
        </div>
        <div
          class="row text-center pt-lg-4 pb-lg-2 align-items-center justify-content-center"
        >
          <div class="col-lg-4">
            <img
              class="img-fluid pt-lg-2"
              width="60%"
              src="@/assets/sponsors/CRo-Brno-H-RGB.png"
              alt="CRo-Brno"
            />
          </div>
          <div class="col-lg-4">
            <a href="https://www.gotobrno.cz/"
              ><img
                class="img-fluid pt-lg-4"
                width="60%"
                src="@/assets/sponsors/logo_gotobrno.png"
                alt="Logo"
            /></a>
          </div>
          <div class="col-lg-4 pt-4 pb-4">
            <img
              class="img-fluid"
              width="50%"
              src="@/assets/sponsors/CRo-Jazz-Z-BLACK.png"
              alt="CRo-Jazz-Z-BLACK"
            />
          </div>
        </div>
        <!--        <div class="row text-center pt-lg-4 pb-lg-2">-->
        <!--          <div class="col-lg-4 offset-lg-2 pt-lg-4 pb-lg-4">-->
        <!--            <img class="img-fluid pt-lg-2" width="60%" src="@/assets/sponsors/CRo-Brno-H-RGB.png" alt="CRo-Brno">-->
        <!--          </div>-->
        <!--        </div>-->
        <p class="text-center pt-lg-4 pb-lg-4 pb-lg-0">
          {{ "Sponsors.leader" | staticTranslation }}
        </p>
        <div class="row text-center pb-lg-4">
          <div class="col-lg-4 offset-lg-4">
            <img
              class="img-fluid imgOrganization"
              src="@/assets/sponsors/Logo_cema.jpeg"
              alt="CEMA"
            />
          </div>
        </div>
        <p class="text-center pt-lg-4 pt-4">
          {{ "Sponsors.cooperation" | staticTranslation }}
        </p>

        <div class="row pt-lg-3 pb-lg-4">
          <div
            class="col-lg-4 pt-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/Logo_JAMU.png"
              alt="Brno_stred"
            />
          </div>

          <div
            class="col-lg-4 pt-4 pb-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="50%"
              src="@/assets/sponsors/Logo_MG.png"
              alt="Logo_MG"
            />
          </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              src="@/assets/sponsors/audioart.png"
              alt="logo"
            />
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4">
          <div
            class="col-lg-4 pb-3 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="30%"
              src="@/assets/sponsors/world_music.png"
              alt="logo"
            />
          </div>

          <div
            class="col-lg-4 pt-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/logo_alfapassage.png"
              alt="logo_alfapassage"
            />
          </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="30%"
              src="@/assets/sponsors/logo_Muzeum_města_Brna.jpg"
              alt="Muzeum_města_Brna"
            />
          </div>
        </div>
        <div class="row pt-lg-4 pb-lg-4 pt-4">
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="50%"
              src="@/assets/sponsors/ZUS_open.png"
              alt="logo"
            />
          </div>

          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="45%"
              src="@/assets/sponsors/LOGO_WEB_ZERO_PADDING.svg"
              alt="logo"
            />
          </div>

          <div
            class="col-lg-4 pt-3 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="70%"
              src="@/assets/sponsors/Indies.jpeg"
              alt="Indies"
            />
          </div>
        </div>
        <div class="row pt-lg-4 pb-lg-4 pt-4">
          <div
            class="col-lg-4 pb-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="50%"
              src="@/assets/sponsors/dpmb_eps.png"
              alt="dpmb"
            />
          </div>

          <div
            class="col-lg-4 pb-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="50%"
              src="@/assets/sponsors/brnensky-varhanni-festival.jpg"
              alt="logo"
            />
          </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="30%"
              src="@/assets/sponsors/logo betelnej betl.jpeg"
              alt="betelnej betl"
            />
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4">
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="60%"
              src="@/assets/sponsors/PonavaFest.png"
              alt="PonavaFest"
            />
          </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="60%"
              src="@/assets/sponsors/music_lab_logo_white.jpeg"
              alt="Logo_MG"
            />
          </div>

          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="50%"
              src="@/assets/sponsors/logo Kabinet múz.png"
              alt="Kabinet múz"
            />
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4">
          <div
            class="col-lg-4 pb-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="50%"
              src="@/assets/sponsors/Romano_hangos.jpg"
              alt="Romano_hangos"
            />
          </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="80%"
              src="@/assets/sponsors/vidensky_logo.png"
              alt="vidensky_logo"
            />
          </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <img
              class="img-fluid"
              width="80%"
              src="@/assets/sponsors/logo-společenství romů.jpeg"
              alt="společenství romů"
            />
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4">
                  <div
                    class="col-lg-4 pb-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
                  ><img
                        class="img-fluid"
                        width="60%"
                        src="@/assets/sponsors/ea_architekti.png"
                        alt="vidensky_logo"
                    />
                </div>
          <div
            class="col-lg-4 pb-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          ><img
                class="img-fluid"
                width="60%"
                src="@/assets/sponsors/logo_cerne_proglas.png"
                alt="vidensky_logo"
            />
        </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          >
            <a href="https://www.kudyznudy.cz/"
              ><img
                class="img-fluid"
                width="80%"
                src="@/assets/sponsors/Kudy-z-nudy-barevne.png"
                alt="vidensky_logo"
            /></a>
          </div>
          <div
            class="col-lg-4 d-flex pt-md-0 pb-md-0 align-items-center justify-content-center"
          ></div>
        </div>
        <h2 class="text-center pt-lg-4 pt-4 pb-lg-4">
          {{ "Sponsors.subtitle" | staticTranslation }}
        </h2>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>
<script>
import footer from "@/components/base/footer";
import navbar from "@/components/base/navbar";

export default {
  name: "sponsors",
  components: {
    "app-navbar": navbar,
    "app-footer": footer,
  },
};
</script>

<style scoped>
h1 {
  font-size: 3.5em;
  padding-top: 3em;
  padding-bottom: 50px;
  font-family: "Transcript Pro";
}

h2 {
  color: black;
  font-size: 2.5em;
  font-family: "Transcript Pro";
}

.padding-top {
  padding-top: 8rem;
}

p {
  font-family: "Transcript Mono";
  color: #4c4d4f;
}

#sponsors {
  background-color: #ffbe46;
}

.sponsorsBlock {
  background-color: white;
  margin-bottom: 3em;
  padding-top: 4em;
}

/**
Responsive
 */
@media screen and (max-width: 450px) {
  .imgOrganization {
    width: 80%;
  }

  .imgHusa {
    width: 90%;
  }
}
</style>
